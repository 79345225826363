<template>
	<div>
		<a-modal title="业绩详情" :width="800" :visible="visible"  @cancel="handleCancel" :footer="null">
			
			<div>
				<a-spin :spinning="loading">
					<div>
						 <a-month-picker  valueFormat="YYYY-MM" v-model="search.month" format="YYYY-MM" @change="changeMonth"/>
					</div>
					
					<div class="mt24 flex alcenter">
						<div class="ft14 ftw400 cl-info">总填单数：{{total_num}}</div>
						<div class="flex alcenter ml25">
							<div class="ft14 ftw400 cl-info">业绩统计：</div>
							<div class="ft14 ftw500 cl-price">¥{{total_commission}}</div>
						</div>
					</div>
					
					<div class="mt20">
						<div class="wxb-table-gray">
							<a-table rowKey="performance_item_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
							 :loading="loading">
								
								
								
							</a-table>
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			staff_id:{
				type:Number,
				default:0,
			}
			
		},
		data() {
			return {
				loading: false,
				search:{
					month:'',
				},
				pagination:{
					total:0,
					current:1,
					pageSize:10,
				},
				columns: [
					{title: 'ID',dataIndex: 'appointment_id',align: 'center',ellipsis: true},
					{title: '用户手机',dataIndex: 'member_mobile',align: 'center'},
					{title: '预约业绩',dataIndex: 'need_pay',align: 'center'},
					{title: '额外增值',dataIndex: 'added_price',align: 'center'},
				],
				total_num:0,
				total_commission:0,
				datas: [],
			}
		},
		created() {
			var day = new Date();
			day.setTime(day.getTime());
			var s2 = day.getFullYear()+"-" + (day.getMonth()+1) ;
			this.search.month=s2;
			this.getLists();
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getEmployeePerformanceDetail',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					month:this.search.month,
					staff_id:this.staff_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.total_num=res.total_num;
					this.total_commission=res.total_commission;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			changeMonth(){
				this.pagination.current=1;
				this.getLists();
			},
			
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
	
		}
	}
</script>

<style>
	
	
</style>
